<template>
  <section class="events-list">
    <div class="block md:flex justify-between items-center">
      <h1 class="h1-Alternative uppercase text-center mb-3 md:mb-0">Event Template</h1>

      <button
        class="button_large-Alternative uppercase bg-magenta border rounded-full py-3 lg:py-4 lg:px-8 focus:outline-none"
        @click="
          this.$router.push({
            name: 'NewEventTemplate',
          })
        "
      >
        New Template
      </button>
    </div>

    <div class="mt-4 md:mt-5 lg:mt-10">
      <div class="bg-white dark:bg-dark-bgSecondary border-t-2 border-grey-light dark:border-dark-textSecondary">
        <EventTemplatesListTable />
      </div>
    </div>
  </section>
</template>

<script>
import { ref, reactive } from "vue";

import EventTemplatesListTable from "@Components/pages/event-template-list/EventTemplatesListTable";

export default {
  name: "EventTemplateList",
  components: {
    EventTemplatesListTable,
  },
  setup() {
    const activeClass = ref("text-blue-light dark:text-dark-textAccent border-b-3 border-blue-light dark:border-dark-textAccent");
    const inactiveClass = ref("text-grey-mid");
    const openTab = reactive({
      number: 1,
    });

    function toggleTabs(tabNumber) {
      openTab.number = tabNumber;
    }

    return {
      activeClass,
      inactiveClass,
      openTab,
      toggleTabs,
    };
  },
};
</script>
