<template>
  <div class="overflow-x-auto">
    <table class="text-left w-full">
      <thead>
        <tr>
          <th class="text-xs md:text-sm text-grey-mid font-bold cursor-pointer" @click="sort('name')">
            <div class="flex items-center relative p-3 md:p-3.5 lg:py-4 lg:px-7">
              <SortArrowDownIcon v-if="data.currentSort === 'name' && data.currentSortDir === 'desc'" class="absolute w-4 h-4 left-0" />
              <SortArrowUpIcon v-if="data.currentSort === 'name' && data.currentSortDir === 'asc'" class="absolute w-4 h-4 left-0" />
              <span :class="{ 'pl-2 lg:pl-0': data.currentSort === 'name' }"> Template Name </span>
            </div>
          </th>
          <th class="text-xs md:text-sm text-grey-mid font-bold cursor-pointer" @click="sort('lastUpdatedDate')">
            <div class="flex items-center relative p-3 md:p-3.5 lg:py-4 lg:px-7">
              <SortArrowDownIcon v-if="data.currentSort === 'lastUpdatedDate' && data.currentSortDir === 'desc'" class="absolute w-4 h-4 left-0" />
              <SortArrowUpIcon v-if="data.currentSort === 'lastUpdatedDate' && data.currentSortDir === 'asc'" class="absolute w-4 h-4 left-0" />
              <span :class="{ 'pl-2 lg:pl-0': data.currentSort === 'lastUpdatedDate' }"> Last Updated </span>
            </div>
          </th>
          <th class="text-xs md:text-sm text-grey-mid font-bold w-20">
            <div class="flex items-center relative p-3 md:p-3.5 lg:py-4 lg:px-7">
              <span>Edit</span>
            </div>
          </th>
          <template v-if="isAdmin">
            <th class="text-xs md:text-sm text-grey-mid font-bold w-20">
              <div class="flex items-center relative p-3 md:p-3.5 lg:py-4 lg:px-7">
                <span>Delete</span>
              </div>
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <template v-if="!data.isLoading">
          <tr
            v-for="(e, index) in eventsListDataTable"
            :key="index"
            :class="{
              'bg-off-white-1 dark:bg-dark-bgPrimary': index % 2 === 0,
            }"
          >
            <td
              class="text-xs md:text-sm lg:text-base text-black-1 dark:text-dark-textPrimary font-medium p-3 md:p-3.5 lg:py-4 lg:px-7 cursor-pointer"
              @click="
                this.$router.push({
                  name: 'EventTemplateDetail',
                  params: {
                    templateId: e.id,
                  },
                })
              "
            >
              {{ e.name }}
            </td>
            <td class="text-xs md:text-sm lg:text-base text-black-1 dark:text-dark-textPrimary font-medium p-3 md:p-3.5 lg:py-4 lg:px-7">
              {{ $filters.formatDate(e.lastUpdatedDate) }} - {{ $filters.formatTime(e.lastUpdatedDate) }}
            </td>
            <td class="text-xs md:text-sm lg:text-base text-magenta font-medium p-3 md:p-3.5 lg:py-4 lg:px-7">
              <EditIcon
                class="h-5 w-5 md:h-7 md:w-7 cursor-pointer"
                @click="
                  this.$router.push({
                    name: 'EventTemplateDetail',
                    params: {
                      templateId: e.id,
                    },
                  })
                "
              />
            </td>
            <template v-if="isAdmin">
              <td class="text-xs md:text-sm lg:text-base text-magenta font-medium p-3 md:p-3.5 lg:py-4 lg:px-7">
                <DeleteIcon class="h-5 w-5 md:h-7 md:w-7 cursor-pointer" @click="onDelete(e.id)" />
              </td>
            </template>
          </tr>
        </template>

        <template v-if="data.isLoading">
          <tr
            v-for="n in 10"
            :key="n"
            :class="{
              'bg-grey-light dark:bg-dark-bgPrimary': n % 2 !== 0,
            }"
            class="h-14"
          >
            <td></td>
            <td></td>
            <td></td>
            <template v-if="isAdmin">
              <td></td>
            </template>
          </tr>
        </template>
      </tbody>
    </table>
    <div v-if="eventsListDataTable.length" class="flex border-t-2 border-grey-light pt-4 lg:pt-7">
      <button
        class="
          w-16
          md:w-20
          h-8
          md:h-10
          text-xs
          md:text-base
          uppercase
          font-bold
          text-grey-mid
          dark:text-dark-textPrimary
          bg-white
          dark:bg-dark-bgSecondary
          border-2 border-grey-light
          dark:border-dark-bgPrimary
          rounded-full
          mr-2
          focus:outline-none
        "
        @click.prevent="paginate(1)"
      >
        First
      </button>

      <div v-for="page in totalPages" :key="`pagination-${page}`">
        <span
          v-if="isDisplayPageNumber(page)"
          :class="page === data.currentPage ? 'border-blue-light' : 'border-grey-light dark:border-dark-bgPrimary'"
          class="flex justify-center items-center w-8 md:w-10 h-8 md:h-10 text-grey-mid dark:text-dark-textPrimary rounded-full border-2 shadow-sm cursor-pointer mr-2"
          @click="paginate(page)"
          >{{ page }}</span
        >
      </div>

      <button
        class="
          w-16
          md:w-20
          h-8
          md:h-10
          text-xs
          md:text-base
          uppercase
          font-bold
          text-grey-mid
          dark:text-dark-textPrimary
          bg-white
          dark:bg-dark-bgSecondary
          border-2 border-grey-light
          dark:border-dark-bgPrimary
          rounded-full
          mr-2
          focus:outline-none
        "
        @click.prevent="paginate(totalPages)"
      >
        Last
      </button>
    </div>
  </div>
</template>

<script>
import { computed, reactive, onBeforeMount, h } from "vue";
import { useStore } from "vuex";

import { dynamicPaginate } from "@Helpers/datatable";
import { sleep } from "@Helpers/sleep";
import { useTableData } from "@Libs/hooks/useDataTable";
import { deleteEventTemplate } from "@API/event/template-repository";

import SortArrowUpIcon from "@Assets/images/SortArrowUpIcon";
import SortArrowDownIcon from "@Assets/images/SortArrowDownIcon";
import EditIcon from "@Assets/images/EditIcon";
import DeleteIcon from "@Assets/images/DeleteIcon";
import { get } from "lodash";
import { empty } from "@/utils";

export default {
  name: "EventsListTable",
  components: {
    SortArrowUpIcon,
    SortArrowDownIcon,
    EditIcon,
    DeleteIcon,
  },
  setup() {
    const store = useStore();

    const data = reactive({
      curerntFilterText: "",
      currentSort: "name",
      currentSortDir: "asc",
      currentPage: 1,
      perPage: 10,
      isLoading: false,
      eventTemplateList: [],
    });
    const sortStr = computed({
      get: () => {
        let s = "";
        if (data.currentSortDir === "desc") s += "-";
        return s + data.currentSort;
      },
      set: s => {
        //if s == current sort, reverse
        if (s === data.currentSort) {
          data.currentSortDir = data.currentSortDir === "asc" ? "desc" : "asc";
        } else data.currentSortDir = "asc";
        data.currentSort = s;
      },
    });
    const eventsListDataTable = computed(() => dynamicPaginate(data.eventTemplateList, data.currentPage, data.perPage));
    const totalPages = computed(() => Math.ceil(data.eventTemplateList.length / data.perPage));

    const isAdmin = computed(() => store.getters.isAdmin);

    onBeforeMount(getEventTemplates);

    async function getEventTemplates(isForce = true) {
      try {
        // Show loading
        if (isForce) {
          toggleLoading();
        } else {
          this.$store.dispatch("updateLoadingText", "Getting event template's data");
          store.dispatch("toggleLoading");
        }
        const { fetchEventTemplatesListDataTable } = useTableData();

        data.eventTemplateList = await fetchEventTemplatesListDataTable(data.curerntFilterText, sortStr.value);
      } catch (error) {
        console.log({ error });
      } finally {
        // Hide Loading
        if (isForce) {
          toggleLoading(false);
        } else {
          store.dispatch("closeLoading");
        }
      }
    }

    async function sort(s) {
      sortStr.value = s;

      getEventTemplates(false);
    }

    async function filter(f) {
      data.curerntFilterText = f;

      getEventTemplates(false);
    }

    function paginate(p) {
      if (p === data.currentPage) return;

      // Set currentPage
      data.currentPage = p;
    }

    function toggleLoading(isLoading = true) {
      data.isLoading = isLoading;
    }

    function isDisplayPageNumber(p) {
      if (totalPages.value < 3) return true;
      if (p >= data.currentPage - 1 && p <= data.currentPage + 1) return true;
      return false;
    }

    return {
      data,
      eventsListDataTable,
      totalPages,
      getEventTemplates,
      sort,
      filter,
      paginate,
      isDisplayPageNumber,
      isAdmin,
    };
  },
  beforeUnmount() {
    this.emitter.all.delete("submit-confirm-dialog-event-template-list");
    this.emitter.all.delete("header-input-change");
  },
  mounted() {
    // Listen header input change text
    this.emitter.on("header-input-change", searchText => {
      if (this.$route.name !== "EventTemplateList") return;
      this.$store.commit("SET_EVENT_SEARCH_TEXT", searchText);
      this.filter(searchText);
    });

    // Listen submit confirm dialog
    this.emitter.on("submit-confirm-dialog-event-template-list", async data => {
      this.$store.dispatch("toggleLoading");
      try {
        const { type, payload } = this.$store.state.auth.confirmDialog.confirmAction;
        let result;
        switch (type) {
          case "DELETE_EVENT_TEMPLATE":
            this.$store.dispatch("updateLoadingText", "Removing event");
            result = await deleteEventTemplate(payload);
            break;
          default:
            return;
        }
        // Show Alert
        if (!empty(result)) {
          this.$store.dispatch("toggleAlert", {
            isAlert: true,
            status: result.success,
            title: result.success ? "Success!" : "Failed!",
            message: result.message,
          });
        }
        this.$store.dispatch("resetConfirmDialog");
      } catch (error) {
        console.error({ error });
      } finally {
        this.$store.dispatch("closeLoading");
        this.getEventTemplates(false);
        await sleep(5000);
        // Hide Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: false,
        });
      }
    });
  },
  methods: {
    get,
    onDelete(eventId) {
      // Show confirm dialog
      this.$store.dispatch("toggleConfirmDialog", {
        eventListen: "submit-confirm-dialog-event-template-list",
        isConfirmDialog: true,
        title: "Delete event template",
        message: "Are you sure you want to delete selected event template? All of your data will be permanently removed. This action cannot be undone.",
        confirmButtonText: "Delete",
        confirmAction: {
          type: "DELETE_EVENT_TEMPLATE",
          payload: eventId,
        },
      });
    },
  },
};
</script>
